import stickybits from 'stickybits'
import Glider from 'glider-js'

const funnelUpdatesMisc = {
    imageModal: null,
    init() {
        this.initModals()
        this.initCarousels()
        this.mobileFiltersToggle()
    },
    initModals() {
        this.imageModal = document.querySelector('.updates-image-modal')
        if (this.imageModal) {
            // listen for clicks on all applicable <img> elements
            document.querySelectorAll('.modal-trigger-img').forEach((trigger) => {
                trigger.addEventListener('click', (e) => {
                    e.preventDefault()
                    this.openModal(trigger.getAttribute('src'))
                })
            })
            // listen for close
            this.imageModal.querySelectorAll('.close-modal').forEach((closeButton) => {
                closeButton.addEventListener('click', (e) => {
                    e.preventDefault()
                    funnelUpdatesMisc.closeModal()
                })
            })
        } else {
            console.log('couldnt find modal element')
        }
    },
    openModal(src) {
        // open modal, lock body and set image src in the modal
        document.body.classList.add('updates-modal-open')
        this.imageModal.classList.add('modal-open')
        const imageEl = this.imageModal.querySelector('.modal-content img')
        imageEl.setAttribute('src', src)
    },
    closeModal() {
        document.body.classList.remove('updates-modal-open')
        this.imageModal.classList.remove('modal-open')
    },
    initCarousels() {
        // carousels using https://nickpiscitelli.github.io/Glider.js/
        const carousels = []
        document.querySelectorAll('.carousel').forEach((carousel) => {
            const slides = carousel.querySelector('.carousel-slides')
            carousels.push(new Glider(slides, {
                slidesToShow: 1,
                draggable: true,
                scrollLock: true,
                dragVelocity: 1,
                arrows: {
                    prev: carousel.querySelector('.glider-prev'),
                    next: carousel.querySelector('.glider-next'),
                },
            }))
        })
    },
    mobileFiltersToggle() {
        const filterToggle = document.querySelector('.filter-mobile-toggle')
        const filtersContainer = document.querySelector('.updates-filters-inner')
        if (filterToggle) {
            filterToggle.addEventListener('click', () => {
                filtersContainer.classList.toggle('mobile-tags-open')
            })
        }
    },
}
// this mostly handles the filtering and searching of updates entries
const funnelUpdates = {
    typeElements: null,
    moduleElements: null,
    searchElement: null,
    typeFilters: '',
    moduleFilters: '',
    debounceTime: 2250, // this should be in sync with the .is-loading class in src/styles/scss/updates.scss
    debounceTimeout: null,
    init() {
        // dont init sticky sidebar if body isnt tall enough
        if (document.querySelector('.updates-body').offsetHeight > 1000) {
            setTimeout(() => {
                // slight timeout as stickybits must be init'd after all images and etc have loaded
                stickybits('.updates-filters-inner', { useFixed: true })
            }, 250)
        }
        this.initFilters()
    },
    initFilters() {
        funnelUpdates.searchElement = document.querySelector('input[name="updates-search"]')
        funnelUpdates.typeElements = document.querySelectorAll('input[type="checkbox"][name="update-type"]')
        funnelUpdates.moduleElements = document.querySelectorAll('input[type="checkbox"][name="update-modules"]')
        funnelUpdates.filterListen(funnelUpdates.typeElements, funnelUpdates.updateFilters)
        funnelUpdates.filterListen(funnelUpdates.moduleElements, funnelUpdates.updateFilters)
        funnelUpdates.searchListen()
        funnelUpdates.removeFilterListen()
    },
    filterListen(elements, callback) {
        elements.forEach((filter) => {
            filter.addEventListener('change', (e) => {
                clearTimeout(funnelUpdates.debounceTimeout)
                funnelUpdates.startLoading()
                funnelUpdates.debounceTimeout = setTimeout(() => {
                    callback()
                }, funnelUpdates.debounceTime)
            })
        })
    },
    searchListen() {
        // listen for user input
        funnelUpdates.searchElement.addEventListener('keyup', (e) => {
            // if user stops typing for two seconds disable input and refresh
            clearTimeout(funnelUpdates.debounceTimeout)
            funnelUpdates.startLoading()
            if (funnelUpdates.searchElement.value.length > 2) {
                funnelUpdates.debounceTimeout = setTimeout(() => {
                    funnelUpdates.searchElement.disabled = true
                    funnelUpdates.searchElement.parentNode.classList.add('loading')
                    funnelUpdates.updateFilters()
                }, funnelUpdates.debounceTime)
            }
        })
    },
    removeFilterListen() {
        const activeFilters = document.querySelectorAll('.active-filter')
        activeFilters.forEach((filter) => {
            filter.addEventListener('click', (e) => {
                if (filter.getAttribute('data-filter-type') === 'filter-search') {
                    funnelUpdates.searchElement.value = ''
                    funnelUpdates.searchElement.disabled = false
                    funnelUpdates.searchElement.parentNode.classList.remove('loading')
                } else {
                    const filterValue = filter.getAttribute('data-filter-value')
                    const filterElement = document.querySelector(`input[value="${filterValue}"]`)
                    filterElement.checked = false
                }
                filter.classList.add('is-hidden')
                clearTimeout(funnelUpdates.debounceTimeout)
                funnelUpdates.startLoading()
                funnelUpdates.debounceTimeout = setTimeout(() => {
                    funnelUpdates.updateFilters()
                }, funnelUpdates.debounceTime)
            })
        })
    },
    startLoading() {
        // TODO remove commented code when we're happy with new loading style
        // const filterContainers = document.querySelectorAll('.filter-inner')
        const loadingBar = document.querySelector('.loading-indicator')
        // filterContainers.forEach((el) => {
        //     el.classList.remove('is-loading')
        // })
        loadingBar.classList.remove('is-loading')
        setTimeout(() => {
            // filterContainers.forEach((el) => {
            //     el.classList.add('is-loading')
            // })
            loadingBar.classList.add('is-loading')
        }, 5)
    },
    updateFilters() {
        funnelUpdates.setTypeFilter()
        funnelUpdates.setModulesFilter()
        const params = new URLSearchParams({
            type: funnelUpdates.typeFilters,
            modules: funnelUpdates.moduleFilters,
            search: funnelUpdates.searchElement.value,
        })
        window.location.href = `/funnel?${params.toString()}`
    },
    setTypeFilter() {
        funnelUpdates.typeFilters = funnelUpdates.getValues(funnelUpdates.typeElements)
    },
    setModulesFilter() {
        funnelUpdates.moduleFilters = funnelUpdates.getValues(funnelUpdates.moduleElements)
    },
    getValues(elements) {
        let filterValues = ''
        elements.forEach((el) => {
            if (el.checked) {
                filterValues += `${el.value},`
            }
        })
        filterValues = filterValues.replace(/,\s*$/, '')
        return filterValues
    },
}

window.addEventListener('DOMContentLoaded', (event) => {
    funnelUpdates.init()
    funnelUpdatesMisc.init()
})
