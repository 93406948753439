
<template>
    <div class="nav-outer" :style="{'background-color': backgroundColor}">
        <nav class="d-navigation" :class=" navColorScheme + (mobileActive ? ' mobile-active' : '')">
            <div class="nav-background lg:hidden"></div>
            <div class="flex items-center justify-between flex-col lg:flex-row">
                <a :href="`${siteUrl}`" class="w-full lg:w-3/12">
                    <img class="digistorm-logo" src="/assets/digistorm.svg" alt="Digistorm Logo">
                    <img class="digistorm-logo-white" src="/assets/digistorm-white.svg" alt="Digistorm Logo White">
                </a>
                <div class="w-6/12 hidden lg:block desktop-nav">
                    <ul v-if="navigation" class="d-nav-list flex items-center justify-center" :class="{'dropdownActive': dropdownIndex !== -1}">
                        <template v-for="(page, index) of navigation">
                            <li :ref="`page-${page.slug}`" :key="index" v-if="page.typeId !== '14'">
                            <tippy
                                :trigger="page.children ? 'mouseenter focus click' : ''"
                                theme="light"
                                placement="bottom-start"
                                interactive
                                boundary="window"
                                offset="-5, 0"
                                @show="dropdownIndex = index"
                                @hide="dropdownIndex = -1"
                            >
                                <template v-slot:trigger>
                                    <a class="main-page block text-sm font-medium py-4 flex items-center " :class="{ 'dropdownActive': dropdownIndex === index, 'cursor-default': page.slug === 'products' || page.slug === 'resources' || page.slug === 'about' }" :href="page.slug !== 'products' && page.slug !== 'resources' && page.slug !== 'about' ? `${siteUrl}${page.uri}` : null">
                                        <span>{{ page.title }}</span>
                                        <span class="ml-2" v-if="page.children">
                                            <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path class="nav-chevron" d="M1 1L4 4L7 1" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </a>
                                </template>
                                <ul class="d-child-nav-list pl-8 pr-8 pt-9 pb-2 text-left">
                                    <li v-for="(child, childIndex) in page.children" :key="childIndex" :class="`${classes(child.slug)}`">
                                        <tippy
                                            trigger=""
                                            theme="light"
                                            placement="right"
                                            interactive
                                        >
                                            <template v-slot:trigger>
                                                <a class="child-item block text-sm font-medium flex items-center mb-6" :class="{ 'flex-col': child.navImage, 'external-link': child.openExternally }" :href="child.redirect ? child.redirect : `${siteUrl}${child.uri}`" :target="child.openExternally ? '_blank' : ''">

                                                    <template v-if="child.navImage">

                                                        <div v-if="child.navImage" class="nav-image flex" :class="`${child.slug}`">
                                                            <img :src="child.navImage" />
                                                            <img class="external-link" src="/assets/external-link.svg"/>
                                                        </div>
                                                        <div>
                                                            <div class="mt-1 text-xs font-normal" v-if="child.description && child.description.length">{{ child.description }}</div>
                                                        </div>

                                                    </template>
                                                    <template v-else>
                                                        <span v-if="page.slug === 'products' && isProduct(child.slug)" class="mr-4"><img :src="`/assets/${child.slug}-darker.svg`" /></span>
                                                        <span class="child-text flex flex-col">
                                                            <span>{{child.title}}</span>
                                                            <span class="mt-1 text-xs font-normal" v-if="child.description && child.description.length">{{ child.description }}</span>
                                                        </span>
                                                        <template v-if="!child.openExternally">
                                                            <svg width="26px" height="17px" viewBox="0 0 26 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                                                    <g transform="translate(-514.000000, -207.000000)" stroke="#2B3A5A" stroke-width="1.1">
                                                                        <g transform="translate(515.000000, 208.000000)">
                                                                            <polyline points="17.0520008 -7.46069902e-15 24.4020011 7.35000033 17.0520008 14.7000007"></polyline>
                                                                            <path d="M24.4020011,7.35000033 L2.43005377e-12,7.35000033" ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </template>
                                                        <template v-else>
                                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.498201 10.3064C0.773707 10.5819 1.22044 10.5818 1.49574 10.3061L9.41193 2.37784L9.40071 7.40761C9.39985 7.79258 9.71169 8.10511 10.0967 8.10511C10.481 8.10511 10.7926 7.79352 10.7926 7.40916V0.999999C10.7926 0.447715 10.3449 0 9.79261 0L3.38426 0C3.00549 0 2.69746 0.305195 2.69395 0.683949C2.6904 1.0677 3.0005 1.38068 3.38426 1.38068L8.41477 1.38068L0.497823 9.30966C0.222822 9.58508 0.222991 10.0312 0.498201 10.3064Z" fill="#494B50"/>
                                                            </svg>
                                                        </template>

                                                    </template>

                                                </a>
                                            </template>
                                            <ul>
                                                <li v-for="(subchild, subchildIndex) in child.children" :key="subchildIndex">
                                                    {{subchild.title}}
                                                </li>
                                            </ul>
                                        </tippy>
                                    </li>
                                </ul>
                            </tippy>
                        </li>
                        </template>
                        <li v-if="isUpdatesSite == 1">
                            <a href="https://support.digistorm.com/" class="support-link text-sm font-medium mr-8 hover:opacity-100 cursor-pointer" target="_blank">Support Hub</a>
                        </li>
                    </ul>
                </div>

                <div v-if="isUpdatesSite == 0" class="w-3/12 hidden lg:block">
                    <div class="sup flex w-full justify-end items-center">
                        <a href="https://support.digistorm.com/" class="support-link text-sm font-medium mr-8 opacity-75 hover:opacity-100 cursor-pointer">Support</a>
                        <a :href="demoUrl" class="btn btn-book btn-blue-dark ml-2 text-sm font-medium color-scheme-light-hide" :class="{'hidden': showDemo == 0}">Book a Demo</a>
                        <a :href="demoUrl" class="btn btn-book btn-blue-light border border-white ml-2 text-sm font-medium color-scheme-dark-hide" :class="{'hidden': showDemo == 0}">Book a Demo</a>
                    </div>
                </div>

                <div class="burger lg:hidden" @click="mobileActive = !mobileActive">
                    <div class="burger-element">
                        <span class="burger-layer"></span>
                        <span class="burger-layer"></span>
                        <span class="burger-layer"></span>
                    </div>
                </div>
                <div class="block lg:hidden mobile-nav w-full">
                    <ul v-if="navigation" class="d-nav-list flex flex-col mt-12 pb-4 w-full" :style="`height: ${mobileMenuHeight}px;`">
                        <template v-for="(page, index) of navigation">
                        <li :ref="`page-${page.slug}`" :key="index" v-if="page.typeId !== '14'">
                            <a  class="main-page block text-xl font-semibold py-3-5 flex items-center "
                                :class="{ 'cursor-default': page.slug === 'products', 'mobile-active': mobileIndex === index }"
                                :href="page.slug !== 'products' && page.slug !== 'resources' && page.slug !== 'about' ? `${siteUrl}${page.uri}` : null"
                                @click="mobileIndex = mobileIndex === index ? -1 : index"
                                >
                                <span>{{ page.title }}</span>
                                <span class="ml-2" v-if="page.children">
                                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path class="nav-chevron" d="M1 1L4 4L7 1" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </a>
                            <ul class="d-child-nav-list pl-8 pr-8 pt-4 pb-2 text-left" :class="{'mobile-active': mobileIndex === index}" v-if="page.children">
                                <li v-for="(child, childIndex) in page.children" :key="childIndex" :class="`${classes(child.slug)}`">
                                    <a class="child-item block text-sm font-medium flex items-center mb-2" :class="{ 'flex-col': child.navImage }" :href="child.redirect ? child.redirect : `${siteUrl}${child.uri}`" :target="child.openExternally ? '_blank' : ''">
                                        <template v-if="child.navImage">

                                            <div v-if="child.navImage" class="nav-image flex">
                                                <img :src="child.navImage" />
                                                <img class="external-link" src="/assets/external-link.svg"/>
                                            </div>
                                            <div class="w-full">
                                                <div class="mt-1 text-xs font-normal" v-if="child.description && child.description.length">{{ child.description }}</div>
                                            </div>

                                        </template>
                                        <template v-else>
                                            <span v-if="page.slug === 'products' && isProduct(child.slug)" class="mr-4"><img :src="`/assets/${child.slug}-darker.svg`" /></span>
                                            <span class="child-text flex flex-col">
                                                <span class="font-semibold">{{child.title}}</span>
                                                <span class="text-xs font-normal" v-if="child.description && child.description.length">{{ child.description }}</span>
                                            </span>
                                            <svg width="26px" height="17px" viewBox="0 0 26 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                                    <g transform="translate(-514.000000, -207.000000)" stroke="#2B3A5A" stroke-width="1.1">
                                                        <g transform="translate(515.000000, 208.000000)">
                                                            <polyline points="17.0520008 -7.46069902e-15 24.4020011 7.35000033 17.0520008 14.7000007"></polyline>
                                                            <path d="M24.4020011,7.35000033 L2.43005377e-12,7.35000033" ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </template>
                                    </a>
                                    <ul>
                                        <li v-for="(subchild, subchildIndex) in child.children" :key="subchildIndex">
                                            {{subchild.title}}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        </template>
                        <li>
                            <a href="https://support.digistorm.com/" class="main-page block text-xl font-semibold py-4 flex items-center ">
                                <span v-if="isUpdatesSite == 0">Support</span>
                                <span v-if="isUpdatesSite == 1">Support Hub</span>
                            </a>
                        </li>
                    </ul>
                    <div>
                        <div v-if="isUpdatesSite == 0" class="book-demo-mobile sup flex w-full">
                            <a :href="demoUrl" class="btn w-full text-center m-5 btn-blue-dark text-sm font-medium">Book a Demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: 'DNavigation',
        props: {
            pages: [Array, Object],
            pageFields: [Array, Object],
            entryType: String,
            currentPage: String,
            backgroundColor: String,
            colorScheme: String,
            demoUrl: String,
            siteUrl: String,
            showDemo: String,
            isUpdatesSite: String,
        },
        data() {
            return {
                dropdownIndex: -1,
                mobileActive: false,
                mobileIndex: -1,
                mobileMenuHeight: 1000,
                navColorScheme: this.colorScheme,
            }
        },
        computed: {
            navigation() {
                const pages = []
                let parentPage = null
                let previousPage = null
                _.each(this.pages, (page, index) => {
                    const fields = _.find(this.pageFields, { id: page.id })
                    page.description = _.get(fields, 'description', '')
                    page.openExternally = _.get(fields, 'openExternally', '')
                    page.redirect = _.get(fields, 'redirect', '')
                    page.navImage = _.get(fields, 'navImage', '')
                    if (_.get(previousPage, 'level') < page.level) {
                        if (!page.uri.startsWith(previousPage.uri)) {
                            return // ensure not child of hidden page
                        }
                        parentPage = previousPage
                    }

                    if (page.level === 1) {
                        pages.push(page)
                    } else {
                        if (_.has(parentPage, 'children')) {
                            parentPage.children.push(page)
                        } else {
                            _.set(parentPage, 'children', [page])
                        }
                    }

                    previousPage = page
                })
                return pages
            },
        },
        watch: {
            mobileActive() {
                if (this.mobileActive) {
                    document.getElementsByTagName('body')[0].classList.add('mobileMenuActive')
                    this.mobileMenuHeight = window.innerHeight - 195
                } else {
                    document.getElementsByTagName('body')[0].classList.remove('mobileMenuActive')
                }
            },
        },
        methods: {
            isProduct(slug) {
                return ['school-apps', 'funnel', 'websites'].includes(slug)
            },
            classes(slug) {
                return this.isProduct(slug) ? 'is-product' : 'is-other'
            },
        },
        mounted() {
            const self = this
            // custom event used on book demo pages
            window.addEventListener('setNavColorDark', () => {
                self.navColorScheme = 'color-scheme-dark'
            })
        },
    }
</script>

<style lang="scss">
    .tippy-tooltip {
        box-shadow: 0 6px 16px 0 rgba(0,0,0,0.06)!important;
        border: 1px solid rgba(246,248,251,0.8);
    }
    .nav-outer {
        @media (max-width: 1023px) {
            height: 80px;
        }
    }
    .d-child-nav-list {
        color: #494B50;
        @media (max-width: 1023px) {
            transform: translateY(-20px);
            opacity: 0;
            visibility: hidden;
            border-bottom: 1px solid transparent; // hack for ios issue
            max-height: 0;
            transition: transform .3s, opacity .3s, visibility .3s, max-height .2s .1s, padding .2s .2s;
            &:not(.mobile-active) {
                padding-top: 0px;
                padding-bottom: 0px;
            }
            &.mobile-active {
                transform: translateY(0px);
                opacity: 1;
                visibility: visible;
                max-height: 100vh;
                overflow-y: scroll;
                transition: transform .3s .1s, opacity .3s .1s, visibility .3s .1s, max-height .3s, padding .2s;
            }
        }
        @apply text-left;
        min-width: 356px;
        .child-item {
            position: relative;
            padding: 4px;
            &.flex-col {
                align-items: start;
            }
            &.external-link {
                svg {
                    height: 10px;
                    height: 10px;
                    margin-top: -3px;
                }
                &:hover svg {
                    transform: translateX(0px) translateY(-5px);
                }
            }
            img {
                width: 36px;
                height: 36px;
            }
            .nav-image {
                width: 100%;
                img {
                    width: 126px;
                    height: 36px;
                }
                &.the-admissions-club {
                    border-top: 2px solid #F6F8FB;
                    img {
                        margin-top: 2em;
                        width: 132px;
                        height: 44px;
                    }
                }
                svg {
                    opacity: 1;
                }
                &:hover {
                    img.external-link {
                        transform: translateX(5px) translateY(-5px);
                    }
                }
                img.external-link {
                    height: 20px;
                    width: 50px;
                    margin-top: auto;
                    margin-bottom: 8px;
                    transition: transform 0.2s ease-in-out;
                }
            }
            svg {
                width: 18px;
                height: 20px;
                position: absolute;
                top: 50%;
                margin-top: -10px;
                right: 10px;
                transition: transform 0.4s, opacity 0.4s;
                transform: translateX(-10px);
                opacity: 0;
            }
            &:hover svg {
                @apply opacity-100;
                transform: translateX(0px);
            }
        }
        .is-product + .is-other {
            border-top: 2px solid #f6f8fb;
            padding-top: 30px;
            margin-top: 24px;
        }
    }
    .d-navigation {
        max-width: 1710px;
        margin-left: auto;
        margin-right: auto;
        padding: 50px 40px;
        z-index: 30;
        position: relative;
        @media (max-width: 1230px) {
            padding: 40px;
        }
        @media (max-width: 1023px) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            padding: 20px 1.25rem;
            height: 80px;
            transition: height 0s .3s;
            overflow: hidden;
            background-color: rgba(255,255,255,0);
            .nav-background {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                visibility: hidden;
                background: #fff;
                transition: opacity .3s, visibility .3s;
                z-index: -1;
            }
            &.mobile-active {
                position: fixed;
                overflow: hidden;
                height: 100vh;
                .nav-background {
                    opacity: 1;
                    visibility: visible;
                }
                .d-nav-list {
                    position: relative;
                    transition: height 0s;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    width: 100%;
                    > li {
                        opacity: 1;
                        @for $i from 1 through 30 {
                            &:nth-child(#{$i}) {
                                transition: opacity 0.3s ($i * 0.1s);
                            }
                        }
                    }
                    &:after {
                        content: '';
                        display: block;
                        position: fixed;
                        bottom: 80px;
                        left: 0;
                        right: 0;
                        height: 40px;
                        opacity: 1;
                        background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
                        pointer-events: none;
                    }
                }
                .burger {
                    .burger-element {
                        .burger-layer {
                            background-color: #494b50 !important;
                        }
                    }
                }
            }
            .d-nav-list {
                > li {
                    transition: opacity .3s;
                    opacity: 0;
                    > a {
                        .nav-chevron {
                            @apply stroke-current;
                        }
                    }
                }
            }
        }
        .desktop-nav {
            .d-nav-list {
                &:hover, &.dropdownActive {
                    li {
                        a {
                            opacity: 0.5;
                            &:hover, &.dropdownActive {
                                opacity: 1;
                            }
                        }
                    }
                }
                li {
                    &:focus,
                    div:focus,
                    & > a:focus {
                        outline: none;
                    }
                    a {
                        transition: opacity .3s;
                        @media (min-width: 769px) {
                            margin: 0px 20px;
                        }
                        .nav-chevron {
                            @apply stroke-current;
                        }
                        svg {
                            transition: transform .3s;
                        }
                        &.dropdownActive {
                            svg {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    &:first-of-type {
                        a {
                            margin-left: 0px;
                        }
                    }
                    &:last-of-type {
                        a {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
        .mobile-nav {
            max-width: 100%;
        }
        @media (max-width: 1023px) {
            .burger {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 50px;
                height: 44px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: transform .3s, background-color .3s;
                .burger-element {
                    position: relative;
                    width: 26px;
                    height: 20px;
                    transition: transform .3s;
                    .burger-layer {
                        position: absolute;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 2px;
                        border-radius: 1px;
                        background-color: #494b50;
                        transition: transform .21s, margin .21s .22s, opacity .3s, top .21s .22s;
                        &:nth-child(1) {
                            top: 0;
                        }
                        &:nth-child(2) {
                            top: 50%;
                            margin-top: -1px;
                        }
                        &:nth-child(3) {
                            top: 100%;
                            margin-top: -2px;
                        }
                    }
                }
            }
            .book-demo-mobile {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s, visibility .3s;
            }
            &.mobile-active {
                .burger {
                    .burger-element {
                        // transform: rotate(90deg);
                        transition: transform .5s;
                    }
                    .burger-layer {
                        transition: transform .21s .22s, margin .22s, opacity .3s, top .22s;
                        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                            margin-top: 0px;
                            top: 50%;
                        }
                        &:nth-child(1) {
                            transform: rotate(45deg);
                        }
                        &:nth-child(2) {
                            transform: rotate(45deg);
                            opacity: 0;
                        }
                        &:nth-child(3) {
                            transform: rotate(-45deg);
                        }
                    }
                }
                .book-demo-mobile {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .main-page {
                svg {
                    transition: transform .3s;
                }
                &.mobile-active {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .digistorm-logo,
        .digistorm-logo-white {
            width: 144px;
            margin-top: 1px;
            @media (max-width: 1023px) {
                max-width: 133px;
            }
        }
        &.color-scheme-dark {
            @media (max-width: 1023px) {
                .digistorm-logo-white {
                    display: none;
                }
            }
            @media (min-width: 1023px) {
                .digistorm-logo-white {
                    @apply hidden;
                }
            }
            .color-scheme-dark-hide {
                @apply hidden;
            }
        }
        &.color-scheme-light {
            @media (max-width: 1023px) {
                .digistorm-logo, .digistorm-logo-white {
                    transition: opacity .3s, visibility .3s;
                }
                .digistorm-logo {
                    position: absolute;
                    visibility: hidden;
                    opacity: .5;
                }
                &.mobile-active {
                    .digistorm-logo {
                        visibility: visible;
                        opacity: 1;
                    }
                    .digistorm-logo-white {
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }
            @media (min-width: 1023px) {
                .digistorm-logo {
                    @apply hidden;
                }
                a {
                    color: #ffffff;
                }
            }
            .color-scheme-light-hide {
                @apply hidden;
            }
            .burger {
                .burger-element {
                    .burger-layer {
                        background: #fff;
                    }
                }
            }
        }
    }
</style>
